
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "IAddress": [
      "BitcoinAddress",
      "DashAddress"
    ],
    "IAddressBalanceChange": [
      "BitcoinAddressBalanceChange",
      "DashAddressBalanceChange"
    ],
    "IAddressBlock": [
      "BitcoinAddressBlock",
      "DashAddressBlock"
    ],
    "IAddressTransaction": [
      "BitcoinAddressTransaction",
      "DashAddressTransaction"
    ],
    "IAddressUtxo": [
      "BitcoinAddressUtxo",
      "DashAddressUtxo"
    ],
    "IBlock": [
      "BitcoinBlock",
      "DashBlock"
    ],
    "IBlockHash": [
      "BitcoinBlockHash",
      "DashBlockHash"
    ],
    "ICoin": [
      "Bitcoin",
      "Dash"
    ],
    "IConfirmedTransaction": [
      "BitcoinConfirmedTransaction",
      "DashConfirmedTransaction"
    ],
    "IDate": [
      "BitcoinDate",
      "DashDate"
    ],
    "IMempool": [
      "BitcoinMempool",
      "DashMempool"
    ],
    "IRichList": [
      "BitcoinRichList",
      "DashRichList"
    ],
    "IScriptPubKey": [
      "BitcoinScriptPubKey",
      "DashScriptPubKey"
    ],
    "ITransaction": [
      "BitcoinTransaction",
      "DashTransaction"
    ],
    "ITransactionInput": [
      "BitcoinTransactionInput",
      "DashTransactionInput"
    ],
    "ITransactionOutput": [
      "BitcoinTransactionOutput",
      "DashTransactionOutput"
    ],
    "IUnconfirmedAddressTransaction": [
      "BitcoinUnconfirmedAddressTransaction",
      "DashUnconfirmedAddressTransaction"
    ],
    "IUnconfirmedTransaction": [
      "BitcoinUnconfirmedTransaction",
      "DashUnconfirmedTransaction"
    ],
    "PaginatedIAddressBalanceChangeResponse": [
      "BitcoinPaginatedAddressBalanceChangeResponse",
      "DashPaginatedAddressBalanceChangeResponse"
    ],
    "PaginatedIAddressBalanceResponse": [
      "BitcoinPaginatedAddressBalanceResponse",
      "DashPaginatedAddressBalanceResponse"
    ],
    "PaginatedIAddressTransactionResponse": [
      "BitcoinPaginatedAddressTransactionResponse",
      "DashPaginatedAddressTransactionResponse"
    ],
    "PaginatedIAddressUtxoResponse": [
      "BitcoinPaginatedAddressUtxoResponse",
      "DashPaginatedAddressUtxoResponse"
    ],
    "PaginatedIBlockHashResponse": [
      "BitcoinPaginatedBlockHashResponse",
      "DashPaginatedBlockHashResponse"
    ],
    "PaginatedIConfirmedTransactionResponse": [
      "BitcoinPaginatedConfirmedTransactionResponse",
      "DashPaginatedConfirmedTransactionResponse"
    ],
    "PaginatedIRichlistResponse": [
      "BitcoinPaginatedRichlistResponse",
      "DashPaginatedRichlistResponse"
    ],
    "PaginatedITransactionInputResponse": [
      "BitcoinPaginatedTransactionInputResponse",
      "DashPaginatedTransactionInputResponse"
    ],
    "PaginatedITransactionOutputResponse": [
      "BitcoinPaginatedTransactionOutputResponse",
      "DashPaginatedTransactionOutputResponse"
    ],
    "PaginatedIUnconfirmedAddressTransactionResponse": [
      "BitcoinPaginatedUnconfirmedAddressTransactionResponse",
      "DashPaginatedUnconfirmedAddressTransactionResponse"
    ],
    "PaginatedIUnconfirmedTransactionResponse": [
      "BitcoinPaginatedUnconfirmedTransactionResponse",
      "DashPaginatedUnconfirmedTransactionResponse"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IAddress": [
      "BitcoinAddress",
      "DashAddress"
    ],
    "IAddressBalanceChange": [
      "BitcoinAddressBalanceChange",
      "DashAddressBalanceChange"
    ],
    "IAddressBlock": [
      "BitcoinAddressBlock",
      "DashAddressBlock"
    ],
    "IAddressTransaction": [
      "BitcoinAddressTransaction",
      "DashAddressTransaction"
    ],
    "IAddressUtxo": [
      "BitcoinAddressUtxo",
      "DashAddressUtxo"
    ],
    "IBlock": [
      "BitcoinBlock",
      "DashBlock"
    ],
    "IBlockHash": [
      "BitcoinBlockHash",
      "DashBlockHash"
    ],
    "ICoin": [
      "Bitcoin",
      "Dash"
    ],
    "IConfirmedTransaction": [
      "BitcoinConfirmedTransaction",
      "DashConfirmedTransaction"
    ],
    "IDate": [
      "BitcoinDate",
      "DashDate"
    ],
    "IMempool": [
      "BitcoinMempool",
      "DashMempool"
    ],
    "IRichList": [
      "BitcoinRichList",
      "DashRichList"
    ],
    "IScriptPubKey": [
      "BitcoinScriptPubKey",
      "DashScriptPubKey"
    ],
    "ITransaction": [
      "BitcoinTransaction",
      "DashTransaction"
    ],
    "ITransactionInput": [
      "BitcoinTransactionInput",
      "DashTransactionInput"
    ],
    "ITransactionOutput": [
      "BitcoinTransactionOutput",
      "DashTransactionOutput"
    ],
    "IUnconfirmedAddressTransaction": [
      "BitcoinUnconfirmedAddressTransaction",
      "DashUnconfirmedAddressTransaction"
    ],
    "IUnconfirmedTransaction": [
      "BitcoinUnconfirmedTransaction",
      "DashUnconfirmedTransaction"
    ],
    "PaginatedIAddressBalanceChangeResponse": [
      "BitcoinPaginatedAddressBalanceChangeResponse",
      "DashPaginatedAddressBalanceChangeResponse"
    ],
    "PaginatedIAddressBalanceResponse": [
      "BitcoinPaginatedAddressBalanceResponse",
      "DashPaginatedAddressBalanceResponse"
    ],
    "PaginatedIAddressTransactionResponse": [
      "BitcoinPaginatedAddressTransactionResponse",
      "DashPaginatedAddressTransactionResponse"
    ],
    "PaginatedIAddressUtxoResponse": [
      "BitcoinPaginatedAddressUtxoResponse",
      "DashPaginatedAddressUtxoResponse"
    ],
    "PaginatedIBlockHashResponse": [
      "BitcoinPaginatedBlockHashResponse",
      "DashPaginatedBlockHashResponse"
    ],
    "PaginatedIConfirmedTransactionResponse": [
      "BitcoinPaginatedConfirmedTransactionResponse",
      "DashPaginatedConfirmedTransactionResponse"
    ],
    "PaginatedIRichlistResponse": [
      "BitcoinPaginatedRichlistResponse",
      "DashPaginatedRichlistResponse"
    ],
    "PaginatedITransactionInputResponse": [
      "BitcoinPaginatedTransactionInputResponse",
      "DashPaginatedTransactionInputResponse"
    ],
    "PaginatedITransactionOutputResponse": [
      "BitcoinPaginatedTransactionOutputResponse",
      "DashPaginatedTransactionOutputResponse"
    ],
    "PaginatedIUnconfirmedAddressTransactionResponse": [
      "BitcoinPaginatedUnconfirmedAddressTransactionResponse",
      "DashPaginatedUnconfirmedAddressTransactionResponse"
    ],
    "PaginatedIUnconfirmedTransactionResponse": [
      "BitcoinPaginatedUnconfirmedTransactionResponse",
      "DashPaginatedUnconfirmedTransactionResponse"
    ]
  }
};
      export default result;
    